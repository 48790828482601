.inspection-report-container {
    position: relative;

    > .inspection-report {
        height: 65vh;
        max-height: 630px;
    }

    > .loading {
        background-color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) and (orientation:portrait) {
    .inspection-report {
        height: 60vh;
        max-height: 75%;
    }
}

@media only screen and (max-height:750px) and (orientation:portrait) {
    .inspection-report {
        height: 43vh;
    }
}

@media only screen and (max-height: 670px) and (orientation:portrait) {
    .inspection-report {
        height: 39vh;
    }
}